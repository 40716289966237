.mat-form-field {
  display: block !important;
  font-size: 14px;
  letter-spacing: normal !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) translateX(.65em) scale(0.75) !important;
}

fieldset > mat-form-field-appearance-outline:first-child, fieldset > legend:first-child + .mat-form-field-appearance-outline {
  margin-top: 10px;
}

.mat-form-field-appearance-fill.mat-form-field-transparent > div {
  background-color: transparent !important;
}

.mat-mdc-form-field-icon-suffix:not(:has(.mdc-icon-button)) {
  padding-right: .5em !important;
  padding-top: .9em !important;
}

.autocomplete-clear-btn {
  height: 24px !important;
  width: 24px !important;

  .lnr {
    position: absolute;
    left: 0;
    top: 0;
  }

  .mat-mdc-button-touch-target {
    height: 24px !important;
    width: 24px !important;
  }
}

.mat-date-range-input-container  {
  margin-top: 5px;
}
