$base-size: 2.5rem;
$vertical-padding: 0.25em;
$horizontal-padding: 1em;

$avatar-dimension: calc(#{$base-size} - 2 * #{$vertical-padding});

.tremaze-chip {

  border-radius: $base-size;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: $vertical-padding $horizontal-padding;
  height: $base-size;
  box-sizing: border-box;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  transition: background-color 0.3s;
  min-width: 100px;
  text-decoration: none;
  overflow: hidden;


  .tremaze-avatar {
    height: $avatar-dimension;
    width: $avatar-dimension;
    font-size: calc(#{$avatar-dimension} * 0.4) !important;
    margin-left: calc(#{$vertical-padding - $horizontal-padding}) !important;
    margin-right: .3rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;

    * {
      height: inherit !important;
      width: inherit !important;
      font-size: inherit !important;
    }
  }

}

.tremaze-expandable-chip-collection {
  font-size: 1rem;
  display: flex;
  padding: .25em;
  border-radius: 1.8em;
  max-width: 100%;
  align-items: flex-start;
  overflow: hidden;

  &--expanded {

    .tremaze-expandable-chip-collection__Container {
      flex-wrap: wrap;
      height: auto;
    }

    .tremaze-expandable-chip-collection__Expand-Button {
      transform: rotate(90deg);
    }
  }

  &:not(.ng-animating) {
    .tremaze-expandable-chip-collection__Expand-Button {
      &--hidden {
        opacity: 0;
        tab-index: -1;
      }
    }
  }

  &__Hidden-Chips-Preview {
    $dimension: calc($avatar-dimension * 0.9);
    font-size: 1.1em;
    border-radius: 50%;
    font-weight: 600;
    height: $dimension;
    width: $dimension;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }

  &__Expand-Button {
    transition: transform 0.3s ease;
    align-self: start;
    height: $base-size !important;
    width: $base-size !important;
    font-size: 1.2em;
    border: none;
  }

  &__Container {
    flex: 1;
    overflow: hidden;
    gap: 0.45em;
    display: flex;
    height: $base-size;
  }

  .tremaze-chip {
    // prevent content inside chip from wrapping and overflowing, make chips themselves overflow the container
    white-space: nowrap;
    overflow: hidden;
    flex-shrink: 0;
    transition: opacity 300ms ease;
    transition-delay: 300ms;

  }
}
