.form {
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    .mat-form-field {
      min-width: 250px;
      width: 100%;
      max-width: 500px;
    }
  }

  &__col {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  &__col:not(:last-child) {
    margin-right: 10px;
  }
}

.form-grid-wrapper {
  display: grid;
  grid-gap: 1rem;
}

.form-grid-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 2rem;
  align-items: flex-start;
  padding: 1rem 0;
  width: min(calc(1200rem / 16), 100%);

  &--Max-Cols-2 {
    .form-grid {
      grid-template-columns: repeat(
        auto-fill,
                      minmax(max(40%, calc(300rem / 16)), 1fr)
      ) !important;
      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(
          auto-fill,
                        minmax(max(20%, calc(200rem / 16)), 1fr)
        ) !important;
      }
    }
  }

  &--Max-Cols-3 {
    .form-grid {
      grid-template-columns: repeat(
            auto-fill,
                      minmax(max(30%, calc(300rem / 16)), 1fr)
        ) !important;
      @media screen and (max-width: 400px) {
        grid-template-columns: repeat(
            auto-fill,
                        minmax(max(20%, calc(200rem / 16)), 1fr)
            ) !important;
      }
    }
  }

  &--Max-Cols-1 {
    .form-grid {
      grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
      min-width: 100%;
    }
  }

  > * {
    min-width: min(25rem, 100%);
    max-width: 100%;
    flex: 1;
  }

  > .form-grid-container {
    min-width: 100%;
  }
}

.form-grid-item-new-row {
  grid-column-start: 1;

  &.form-grid-item-2-col {
    grid-column-end: 3;
  }

  &.form-grid-item-3-col {
    grid-column-end: 4;
  }
}

.form-grid-item-2-col {
  grid-column: span 2;
}

.form-grid-item-3-col {
  grid-column: span 3;
}

.form-grid-item-full-width {
  grid-column: 1 / -1;
}

mat-tab-body > :first-child > fieldset:first-child {
  margin-top: 15px;
}

.dark-theme {
  fieldset {
    legend {
      color: rgb(211 211 211);
    }
  }
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  border-color: rgba(128, 128, 128, 0.2);
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.04);
  padding-bottom: 20px;

  legend {
    color: #4c4c4c;
  }
}

.form-grid, .form-grid fieldset {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
                  minmax(min(calc(250rem / 16), 100%), 1fr)
  );
  grid-gap: 1.0rem;
  align-items: flex-start;

  &--dense {
    grid-gap: .333rem;
  }

  width: 100%;
  box-sizing: border-box;

  .mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0;
  }

  max-width: 100%;

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    @extend .form-grid-item-full-width;
    margin: 0;
  }

  &--force-vertical {
    grid-template-columns: 1fr;
  }

  &--Center-Items {
    place-items: start center;
  }
}
