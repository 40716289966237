.hide-cb {
  color: white !important;
  cursor: pointer !important;

  .mat-pseudo-checkbox {
    display: none !important;
  }
}

.mat-option-text {
  .mat-icon {
    margin-left: 5px;
    font-size: 22px;
  }
}

.mat-mdc-option {
  padding-top: 4px !important;
  padding-bottom: 4px !important;

  &.mat-option-hide-checkbox {
    .mat-pseudo-checkbox {
      display: none !important;
    }
  }
}

.mdc-list-item__primary-text {
  width: 100%;
}
