.ordered-mat-list {
  .mat-list-item-content {
    flex: 1;
  }
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  align-items: center;
}

.mdc-list-item__primary-text {
  overflow: visible !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}
